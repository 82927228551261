a.abcRioButtonBlue {
  &:hover {
    color: white;
    text-decoration: none;
  }

  background-color: rgb(66, 133, 244);
  background-image: none;
  border-bottom-color: rgb(255, 255, 255);
  border-bottom-left-radius: 1px;
  border-bottom-right-radius: 1px;
  border-bottom-style: none;
  border-bottom-width: 0px;
  border-image-outset: 0px;
  border-image-repeat: stretch;
  border-image-slice: 100%;
  border-image-source: none;
  border-image-width: 1;
  border-left-color: rgb(255, 255, 255);
  border-left-style: none;
  border-left-width: 0px;
  border-right-color: rgb(255, 255, 255);
  border-right-style: none;
  border-right-width: 0px;
  border-top-color: rgb(255, 255, 255);
  border-top-left-radius: 1px;
  border-top-right-radius: 1px;
  border-top-style: none;
  border-top-width: 0px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 4px 0px;
  box-sizing: border-box;
  color: rgb(255, 255, 255);
  cursor: pointer;
  display: block;
  height: 50px;
  outline-color: rgb(255, 255, 255);
  outline-style: none;
  outline-width: 0px;
  overflow-x: hidden;
  overflow-y: hidden;
  position: relative;
  text-align: center;
  transition-delay: 0s, 0s, 0s;
  transition-duration: 0.218s, 0.218s, 0.218s;
  transition-property: background-color, border-color, box-shadow;
  transition-timing-function: ease, ease, ease;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  width: 240px;
  -webkit-appearance: none;
}

.abcRioButtonBlue .abcRioButtonContentWrapper {
  border: 1px solid transparent;
}

.abcRioButtonContentWrapper {
  height: 100%;
  width: 100%;
}

.abcRioButtonBlue .abcRioButtonIcon {
  background-color: #fff;
  -webkit-border-radius: 1px;
  border-radius: 1px;
}

.abcRioButtonIcon {
  float: left;
}

.abcRioButtonSvg {
  display: block;
}

.abcRioButtonContents {
  font-family: Roboto,arial,sans-serif;
  font-weight: 500;
  letter-spacing: .21px;
  margin-left: 6px;
  margin-right: 6px;
  vertical-align: top;
}

.abcRioButtonBlue:hover {
    background-color: #4285f4;
}

.abcRioButton:hover {
  -webkit-box-shadow: 0 0 3px 3px rgba(66,133,244,.3);
  box-shadow: 0 0 3px 3px rgba(66,133,244,.3);
}
