@import "bootstrap/scss/_functions";
@import "bootstrap/scss/_variables";
@import "bootstrap/scss/_mixins";
@import "bootstrap/scss/_root";
@import "bootstrap/scss/_utilities.scss";
@import "./google_oauth_button.scss";

$red: #f44336;

.hidden {
  display: none!important;
}
.center-all {
  display: flex;
  align-items: center;
  justify-content: center;
}

.view-div {
  -webkit-overflow-scrolling: touch;
  top: 56px;
  bottom: 0;
  position: absolute;
  overflow: auto;
  width: 100%;
}

#dropoff-type-select {
  width: 100px;
}

.fit {
  -webkit-overflow-scrolling: touch;
  position: absolute;
  top: 56px;
  bottom: 0;
  overflow: auto;
  width: 100%
}

.mainfit {
  -webkit-overflow-scrolling: touch;
  position: absolute;
  top: 50px;
  bottom: 0;
  overflow: auto;
  width: 100%
}

.muhtable {
  width: 100%;

  th {
    font-weight: 500;
  }

  th,td {
    padding: 2px;
    text-align: center;
  }
}

.align-center {
  text-align: center;
}

.job-row {
  border-bottom: 1px solid rgba(#6c757d, 0.25);
}

.list-group-item {
  border-bottom: 1px solid rgba(#6c757d, 0.25);
}

.text_pending_removal {
  color: #ffa726;
}

.fw-500 {
  font-weight: bold;
}

.building-row {
  font-weight: bold;
}

.multi-user-select {
  select {
    height: 30px !important;
  }
}

.text_removed {
  color: #2196f3;
}

.pending_removal_btn {
  color: $red !important;
  border-color: $red !important;
}

.piggy-span {
  width: 25px;
}

$darkbg:rgba(0, 0, 0, 0.54);
$graybg: #424242;
$lightbg:rgb(250,250,250);

body {
  &.darkmode {
    .piggy-span path {
      fill: $lightbg;
    }

    .file-cover-btn {
      background: $graybg;
    }
  }

  &.lightmode {
    .piggy-span path {
      fill: $darkbg;
    }

    .file-cover-btn {
      background: $lightbg;
    }
  }

}

#fridge_product_select_hold {
  select {
    min-width: 90px;
  }
}